import React, { useState, useRef, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import sliderThumbStyle from "../../../styles/sass/sliderthumb.module.scss";
import Routes from "../../../routes/Routes";
import arrowIcon from "../../../../public/assets/images/arrow-icon.svg";
// import largeImg_1 from "../../../../../public/assets/images/w-c-img-1.jpg";
// import smallImg_1 from "../../../../../public/assets/images/nav-item-1.png";

import Slider from "react-slick";

const SliderThumb = ({ data = {} }) => {
  const { pictures = [] } = data;

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  // eslint-disable-next-line
  const slider1 = useRef();
  // eslint-disable-next-line
  const slider2 = useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
    
  };

  const lengthValue = pictures.length;
  const getLength = (lengthValue > 2 && lengthValue < 5) ? lengthValue :(lengthValue < 7) ? 4 : (lengthValue > 5) ? 5 : 4;

  const settings2 = {
    dots: false,
    slidesToShow: getLength,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };
  if (!pictures.length) return <></>;

  return (
    <>
      <section
        className={`mc_container_fluid ${sliderThumbStyle["slider-o-cb-thumb"]}`}
      >
        <div className="mc_row_new">
          <div className={`${sliderThumbStyle["sliderthumb"]}`}>
            <Slider
              {...settings}
              asNavFor={nav2}
              ref={(slider1) => setNav1(slider1)}
            >
              {pictures?.sort((a, b) => a?.extra?.order - b?.extra?.order)?.map((pic) => (
                <div
                  key={pic?._id}
                  className={`${sliderThumbStyle["slider-item-main"]}`}
                >
                  <div className={`${sliderThumbStyle["slider-img"]}`}>
                    <div className={`${sliderThumbStyle["content-outer-cb"]}`}>
                      <div
                        className={`${sliderThumbStyle["content-inner-cb"]}`}
                      >
                        <div className="mc_container_inner">
                          <div className="mc_row_new">
                            <div className="c-text-container bg_overlay">
                              <div
                                className={`heading-title white-color default-color h-title ${sliderThumbStyle["h-title"]}`}
                                dangerouslySetInnerHTML={{ __html: pic?.title }}
                              />

                              <div
                                dangerouslySetInnerHTML={{
                                  __html: pic?.imgDescription,
                                }}
                              />
                              
                              {pic?.extra?.showCTAButton && (
                                <div className="btn_more_link_cb">
                                <Link href={pic?.extra?.ctaBtnURL || ""}>
                                  <a className="readMoreContent">
                                    {pic?.extra?.ctaBtnText}
                                    <i>
                                      <img src={arrowIcon.src} alt="arrow-icon" />
                                    </i>
                                  </a>
                                </Link>
                              </div>
                              )}
                              


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  <div className={`${sliderThumbStyle["slider-inner-img"]}`}>
                      <Image
                        src={pic?.url}
                        width={1440}
                        height={820}
                        alt={pic?.tag}
                        className={`${sliderThumbStyle["img-cb"]}`}
                          />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div
          className={`mc_row_new slider-thumb ${sliderThumbStyle["thumb-o-cb"]}`}
        >
          <div className="mc_container_inner">
            <div className="mc_row_new">
              <Slider
                asNavFor={nav1}
                {...settings2}
                ref={(slider2) => setNav2(slider2)}
              >
                {pictures?.map((pic) => (
                  <div
                    key={pic?._id}
                    className={`${sliderThumbStyle["thumb-img-cb"]}`}
                  >
                    <div
                      className={`thumb-m-inner-cb ${sliderThumbStyle["thumb-inner-cb"]}`}
                    >
                      <Image
                        src={pic?.thumb_location}
                        alt={pic?.tag}
                        objectFit="cover"
                        layout="fill"
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SliderThumb;
